import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { RingLoader } from "react-spinners";
import { FaSearch } from "react-icons/fa";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import ProjectSelect from "./projectSelectorComponent";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

const { SearchBar } = Search;

const Pakkeliste_NO = () => {
  const [selectedPakkeListe, setSelectedPakkeListe] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [excludeFilter, setExcludeFilter] = useState("");

  useEffect(() => {
    if (!selectedProject) return;

    setLoading(true);
    const url = `https://api2.fairs.as/24so/soap/boligmesse-pakkeliste?projectId=${selectedProject.value}`;

    axios
      .get(url)
      .then((response) => {
        setSelectedPakkeListe(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedProject]);

  const handleExcludeFilterChange = (event) => {
    setExcludeFilter(event.target.value);
  };

  const handlePrint = () => {
    const printContents = document.getElementById("printTable").outerHTML;
    const printWindow = window.open('', '_blank');
    printWindow.document.open();
    printWindow.document.write('<html><head><title>Print</title>');
    printWindow.document.write('<link href="/css/modern.css" rel="stylesheet" type="text/css">');
    printWindow.document.write('<link href="/css/print.css" rel="stylesheet" type="text/css" media="print">');
    printWindow.document.write('<style>.card { page-break-inside: avoid; }</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(printContents);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
  };

  const getFilteredData = () => {
    if (!selectedPakkeListe) return [];
    
    const excludeList = excludeFilter.split(",").map((sku) => sku.trim().toLowerCase());
    
    return selectedPakkeListe.results.filter((item) => !excludeList.includes(item.SKU.toLowerCase()));
  };

  const columns = [
    {
      dataField: "SKU",
      text: "SKU",
      sort: true,
      headerStyle: { width: "15%" }
    },
    {
      dataField: "DESC",
      text: "Description",
      sort: true,
      headerStyle: { width: "70%" }
    },
    {
      dataField: "Quantity",
      text: "Quantity",
      sort: true,
      headerStyle: { width: "15%" }
    }
  ];

  return (
    <>
      <Container fluid>
        <Header>
          <HeaderTitle>Packing List</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Expo Solutions</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Packing List</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <ProjectSelect onProjectSelect={setSelectedProject} />
      </Container>

      {selectedPakkeListe && (
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <CardTitle tag="h5" className="mb-0">Results</CardTitle>
                  <div className="card-actions float-right">
                    <UncontrolledDropdown className="d-inline-block">
                      <DropdownToggle tag="a">
                        ⋮
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={handlePrint}>Print</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </CardHeader>
                <CardBody>
                  <div style={{ marginBottom: "10px" }}>
                    <input
                      type="text"
                      placeholder="Exclude SKUs (comma separated)"
                      value={excludeFilter}
                      onChange={handleExcludeFilterChange}
                      style={{ width: "100%", padding: "5px" }}
                    />
                  </div>
                  <div id="printTable">
                    <ToolkitProvider
                      keyField="id"
                      data={getFilteredData().map((item, index) => ({ ...item, id: `${item.SKU}-${index}` }))}
                      columns={columns}
                      search
                    >
                      {props => (
                        <>
                          <SearchBar {...props.searchProps} className="mb-3" placeholder="Search products" />
                          <BootstrapTable {...props.baseProps} striped hover />
                        </>
                      )}
                    </ToolkitProvider>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}

      {loading && (
        <div className="loading-screen" style={{ marginTop: "125px" }}>
          <RingLoader size={100} color={"#123abc"} loading={loading} />
          <p>Processing, Please Wait...</p>
        </div>
      )}
    </>
  );
};

export default Pakkeliste_NO;
