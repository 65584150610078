import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useHistory } from 'react-router-dom';
import Project from "./ProjectAssembler";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import HeaderSubtitle from "../../../components/HeaderSubtitle";
import { useUserData } from "../../../context/UserDataContext";

const Default = () => {
  const history = useHistory();
  const { userData, setUserDataInContext } = useUserData(); 



  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Welcome back, {userData.name}!</HeaderTitle>
      </Header>
      <Row>
        <Col lg="12" className="col-xxl-12 d-flex">
         <p style={{color:"green"}}>Version build 27.02.2025 v1.2.0</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Default;
